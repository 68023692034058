import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Urbanist\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"urbanist\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/node_modules/react-hot-toast/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/QueryClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/InstallAppPopup.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/IOSAddHomeScreenPOPUp.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/protect-routes.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/utils/Providers.tsx");
