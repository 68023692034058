'use client';
import React from 'react';
import { IOSShareIcon } from '@/components/icons';
import useInstallWPA from '@/hooks/useInstallWPA';

const IosAddHomeScreenPopUp = () => {
  const { isIosWPA } = useInstallWPA();

  return (
    isIosWPA && (
      <div className="fixed bottom-4 z-0 mx-[2.5vw] h-14 w-[95vw] rounded-lg bg-primary-100 drop-shadow-card-shadow">
        <div className="w-fullgit z-10 flex h-full  flex-wrap items-start justify-center rounded-lg bg-primary-100 p-2 text-[4vw] font-semibold text-primary-700">
          <span className="text-nowrap">
            Install this webapp on your IPhone
          </span>
          <span className="flex items-center gap-2 text-nowrap">
            Tap <IOSShareIcon className="m-0 h-[4vw] w-[4vw] p-0" />
            then click on add to home screen
          </span>
        </div>
        <div className="absolute -bottom-2 left-[50%] -z-10 mx-auto h-[4vw] w-[4vw] -translate-x-[50%]  rotate-45 bg-primary-100" />
      </div>
    )
  );
};

export default IosAddHomeScreenPopUp;
