import { useEffect, useState } from 'react';

const useInstallWpa = () => {
  const [installPrompt, setInstallPrompt] = useState<any>(null);
  const [isInstallable, setIsInstallable] = useState<boolean>(false);
  const [isIosWPA, setIsIosWPA] = useState<boolean>(false);

  useEffect(() => {
    // Checking for IOS Feature Implementation
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };

    const isInStandaloneMode = () =>
      'standalone' in window.navigator && window.navigator.standalone;

    const sessionWPAInIOS = window.sessionStorage.getItem('wpaIsInIOS');

    if (isIos() && !isInStandaloneMode() && sessionWPAInIOS !== 'true') {
      setIsIosWPA(true);
      let timeout: NodeJS.Timeout | string = '';
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        setIsIosWPA(false);
        window.sessionStorage.setItem('wpaIsInIOS', 'true');
      }, 10000);
    } else {
      setIsIosWPA(false);
    }

    //Install app on non safari browser
    const handleBeforeInstallPrompt = (event: Event) => {
      event.preventDefault();
      setInstallPrompt(event);
      if (event) {
        setIsInstallable(true);
      }
    };
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    return () =>
      removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  }, []);
  return { installPrompt, isInstallable, isIosWPA };
};

export default useInstallWpa;
