'use client';
import React from 'react';
import { Provider } from 'react-redux';
import store from '@/store';
import { SessionProvider } from 'next-auth/react';

const Providers = ({
  children,
  session,
}: {
  children: React.ReactNode;
  session: any;
}) => {
  return (
    <SessionProvider session={session}>
      <Provider store={store}>{children}</Provider>
    </SessionProvider>
  );
};

export default Providers;
